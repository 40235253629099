<template>
    <div>
        <div
            class="table-header mb-5">
            <div class="theader"></div>
            <div class="theader">Файл</div>
            <div class="theader">Загружен</div>
            <div class="theader">Ответственный</div>
            <div class="theader"></div>
            <div class="theader"></div>
        </div>
        <template v-if="report_files.length">
            <div
                v-for="file, index in report_files"
                :key="`${report.id}_${file.id}`"
                class="files_info items-center">
                <div class="file_name pr-5">
                    <template v-if="file.name">
                        {{ file.name }}:
                    </template>
                    <template v-else>
                        Файл {{ `${index + 1}` }}:
                    </template>
                </div>
                <div v-if="file?.original_file?.path" class="file truncate pr-5">
                    <div v-if="report.file_viewing_is_available && file?.pdf_file?.path">
                        <div @click="openReport(report, file)" class="cursor-pointer blue_color">
                            <a-popover>
                                <template slot="content">
                                    {{ file.original_file.name }}.{{ file.original_file.extension }}
                                </template>
                                <div class="truncate">
                                    {{ file.original_file.name }}.{{ file.original_file.extension }}
                                </div>
                            </a-popover>
                        </div>
                    </div>
                    <div v-else class="truncate">
                        {{ file.original_file.name }}.{{ file.original_file.extension }}
                    </div>
                </div>
                <div v-else>
                    <div class="text-gray-300">
                        Не загружен
                    </div>
                </div>
                <div class="upload_date">
                    <div v-if="file.upload_date">
                        {{ $moment(file.upload_date).format('DD.MM.YYYY в HH.mm') }}
                    </div>
                    <div v-else class="text-gray-300">
                        Не загружен
                    </div>
                </div>
                <div class="uploaded_by">
                    <div v-if="file.uploaded_by">
                        <Profiler :user="file.uploaded_by" />
                    </div>
                    <div v-else class="text-gray-300">
                        Нет данных
                    </div>
                </div>
                <div class="download">
                    <template v-if="file?.original_file?.path && report.file_viewing_is_available">
                        <a-popover>
                            <template slot="content">
                                <p>Скачать файл</p>
                            </template>
                            <a download
                               target="_blank"
                               :href="file.original_file.path">
                                <a-button 
                                    type="link" 
                                    icon="download" />
                            </a>
                        </a-popover>
                    </template>
                    <template v-else>
                        <a-button 
                            type="link" 
                            icon="download"
                            disabled />
                    </template>
                </div>
                <div class="delete">
                    <template v-if="file?.original_file?.path && report.update_is_available">
                        <a-popover>
                            <template slot="content">
                                <p>Удалить файл</p>
                            </template>
                            <a-icon flaticon type="fi-rr-trash" />
                            <a-button 
                                type="link"
                                ghost
                                flaticon
                                icon="fi-rr-trash"
                                :disabled="fileChangeIsDisabled(report)"
                                @click="deleteReportFile(report, file)" />
                        </a-popover>
                    </template>
                    <template v-else>
                        <a-button 
                            type="link"
                            ghost
                            flaticon
                            icon="fi-rr-trash"
                            disabled />
                    </template>
                </div>
            </div>
        </template>
        <div class="disintegration">
            <div class="revoked-without-routing">
                <span class="label">Отозвано без маршрутизации:</span>
                <span v-if="report?.revoked_without_routing !== null" class="value">{{report.revoked_without_routing}}</span>
                <span v-else class="value no-data">Не указано</span>
            </div>
            <div class="transferring-to-another-system">
                <span class="label">Перенос в другую систему:</span>
                <span v-if="report?.transferring_to_another_system !== null" class="value">{{report.transferring_to_another_system}}</span>
                <span v-else class="value no-data">Не указано</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        report: {
            type: Object,
            require: true
        },
        openReport: {
            type: Function,
            default: () => {}
        },
        fileChangeIsDisabled: {
            type: Function,
            default: () => {}
        },
        deleteReportFile: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        report_files() {
            const allowedCodes = ['f2go', 'risk_matrix'];
            const report_files = this.report.report_files.filter(item => allowedCodes.includes(item.code))
            return report_files
        }
    }
}
</script>
<style lang="scss" scoped>
.table-header, .files_info, .disintegration{
    display: grid;
    grid-template-columns: 130px 1fr 150px 250px 40px 40px;
    grid-template-rows: auto;
    min-height: 2.75rem;
    padding: 0.7rem 0 0.7rem 0;
}
.table-header{
    .theader {
        font-weight: 600;
    }
}
.files_info {
    &:not(:nth-last-child(-n+2)){
        border-bottom: 1px solid var(--borderColor);
    }
    .file_name {
        margin-left: 10px;
    }
}
.disintegration{
    margin-top: 10px;
    .revoked-without-routing{
        margin-left: 10px;
        grid-column: span 2;
        align-self: center;
    }
    .transferring-to-another-system{
        grid-column: span 2;
        align-self: center;
    }
    .label{
        margin-right: 20px;
    }
    .no-data{
        color: rgba(209, 213, 219);
    }
   
}
</style>